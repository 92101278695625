<template>
	<div id="MenuBar">
		<div id="logo">
			<img src="@/assets/images/logo.png" alt="" />
		</div>
		<ul class="menu-bar">
			<template v-for="menu in menuLists" :key="menu.value">
				<li
					:class="{
						'menu-bar-item': true,
						active: menu.value === $route.meta.active,
					}"
				>
					<router-link :to="{ name: menu.value }">{{ menu.label }}</router-link>
				</li>
			</template>
			<li class="menu-bar-item">
				<a href="http://www.echuad.com/" target="_blank">溢楚文化</a>
			</li>
		</ul>
	</div>
</template>
<script>
export default {
	name: 'MenuBar',
	data() {
		return {
			menuLists: [
				{ label: '首页', value: 'IndexPage' },
				{ label: '产品介绍', value: 'ProductPage' },
				{ label: '牵星系统', value: 'TractionPage' },
				{ label: '场景案例', value: 'ScenePage' },
				{ label: '申请试用', value: 'ApplyPage' },
				{ label: '关于我们', value: 'AboutPage' },
			],
		}
	},
}
</script>
