export default [
	{
		path: '/',
		name: 'IndexPage',
		component: () => import('@views/IndexPage.vue'),
		meta: {
			active: 'IndexPage',
		},
	},
	{
		path: '/product',
		name: 'ProductPage',
		component: () => import('@views/ProductPage.vue'),
		meta: {
			active: 'ProductPage',
		},
	},
	{
		path: '/traction',
		name: 'TractionPage',
		component: () => import('@views/TractionPage.vue'),
		meta: {
			active: 'TractionPage',
		},
	},
	{
		path: '/scene',
		name: 'ScenePage',
		component: () => import('@views/ScenePage.vue'),
		meta: {
			active: 'ScenePage',
		},
	},
	{
		path: '/scene/md',
		name: 'SceneMD',
		component: () => import('@views/SceneMD.vue'),
		meta: {
			active: 'ScenePage',
		},
	},
	{
		path: '/scene/visa',
		name: 'SceneVisa',
		component: () => import('@views/SceneVisa.vue'),
		meta: {
			active: 'ScenePage',
		},
	},
	{
		path: '/scene/t3',
		name: 'SceneT3',
		component: () => import('@views/SceneT3.vue'),
		meta: {
			active: 'ScenePage',
		},
	},
	{
		path: '/scene/pdl',
		name: 'ScenePDL',
		component: () => import('@views/ScenePDL.vue'),
		meta: {
			active: 'ScenePage',
		},
	},
	{
		path: '/scene/alw',
		name: 'SceneALW',
		component: () => import('@views/SceneALW.vue'),
		meta: {
			active: 'ScenePage',
		},
	},
	{
		path: '/scene/kw',
		name: 'SceneKW',
		component: () => import('@views/SceneKW.vue'),
		meta: {
			active: 'ScenePage',
		},
	},
	{
		path: '/apply',
		name: 'ApplyPage',
		component: () => import('@views/ApplyPage.vue'),
		meta: {
			active: 'ApplyPage',
		},
	},
	{
		path: '/about',
		name: 'AboutPage',
		component: () => import('@views/AboutusPage.vue'),
		meta: {
			active: 'AboutPage',
		},
	},
]
