import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'

export default function () {
	return createRouter({
		history: createWebHistory(),
		routes,
		scrollBehavior() {
			// scroll to id `#app` + 200px, and scroll smoothly (when supported by the browser)
			return {
				el: '#app',
				top: 0,
				left: 0,
				behavior: 'auto',
			}
		},
	})
}
