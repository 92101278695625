<template>
  <MenuBar />
  <router-view />
  <FooterPage />
</template>

<script>
import MenuBar from '@/components/MenuBar.vue'
import FooterPage from '@/components/FooterPage.vue'
export default {
  name: 'App',
  components: {
    MenuBar,
    FooterPage,
  },
}
</script>
