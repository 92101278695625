<template>
	<div class="footer">
		<div class="content">
			<div class="contact">
				<ul>
					<li class="contact-item">
						<span class="title">商务洽谈：</span>
						<a href="mailto:ys-ad@yisheng.email">ys-ad@yisheng.email</a>
						<a href="tel:021-54019818">T: 021-54019818</a>
					</li>
					<li class="contact-item">
						<span class="title">媒体合作：</span>
						<a href="mailto:ys-ad@yisheng.email">ys-ad@yisheng.email</a>
						<a href="tel:021-54019818">T: 021-54019818</a>
					</li>
				</ul>
				<span>溢胜科技：<br />上海市浦东新区浦东大道138号永华大厦11楼AB座</span>
			</div>
			<!-- <ul class="qrcode">
        <li>
          <div class="q-code">
            <img src="https://iph.href.lu/160x160?fg=ffffff&bg=ffffff" alt="" />
          </div>
          <span>关注我们</span>
        </li>
        <li>
          <div class="q-code">
            <img src="https://iph.href.lu/160x160?fg=ffffff&bg=ffffff" alt="" />
          </div>
          <span>溢楚文化</span>
        </li>
      </ul> -->
		</div>
		<div class="copyright">
			<span>Copyright © 2022 上海溢胜信息科技有限公司 All Rights Reserved<br />沪ICP备19022110号-2 隐私条款 | 服务条款</span>
		</div>
	</div>
</template>
